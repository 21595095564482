import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { ButtonEmphases } from '@thg-commerce/gravity-elements/Button/Button'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

import {
  ButtonsContainer,
  Description,
  StyledButton,
  Subtitle,
  TextGridContainer,
  TextGridItem,
  Title,
} from './styles'

const PrefetchStyledButton = withPrefetch('href', StyledButton)
const GRID_COLS = 24
const MAX_NUMBER_OF_COLUMNS = 6
const NUMBER_OF_COLUMNS_TO_GRID_SPAN = {
  1: [24, 24, 24, 24],
  2: [24, 12, 12, 12],
  3: [24, 8, 8, 8],
  4: [24, 12, 6, 6],
  5: [24, 12, 4, 4],
  6: [24, 12, 4, 4],
}

export interface ButtonProps {
  text: string
  link: string
  emphasis?: ButtonEmphases
}

export interface SimpleTextItem {
  subtitle?: string
  title?: string
  titleHeadingLevel?: 'h1' | 'h2'
  content?: string
  buttons?: [ButtonProps, ButtonProps?]
}

export interface SimpleTextProps {
  alignment?: HorizontalAlignment
  numberOfColumns?: number
  items: [SimpleTextItem, ...SimpleTextItem[]]
  widgetSimpleTextHideMarginBottom?: boolean
}

export const SimpleText = ({
  widgetSimpleTextHideMarginBottom,
  alignment = 'center',
  ...props
}: SimpleTextProps) => {
  if (props.items.length === 0) {
    return null
  }
  const maxNumberOfColumns = Math.min(props.items.length, MAX_NUMBER_OF_COLUMNS)

  const numberOfColumns =
    props.numberOfColumns === undefined
      ? maxNumberOfColumns
      : Math.min(Math.max(props.numberOfColumns, 1), maxNumberOfColumns)

  const colSpanArray = NUMBER_OF_COLUMNS_TO_GRID_SPAN[numberOfColumns]

  const getColStart = (
    alignment: 'center' | 'right',
    index: number,
    colSpan: number,
  ) => {
    if (numberOfColumns === 1) {
      return alignment === 'center' ? 1 : 5
    }
    const numOfItemsPerRow = GRID_COLS / colSpan
    const numOfItemsInFinalRow = props.items.length % numOfItemsPerRow

    if (index < props.items.length - numOfItemsInFinalRow) {
      return colSpan * (index % numOfItemsPerRow) + 1
    }
    const countFromFinalItem = props.items.length - index

    return alignment === 'center'
      ? GRID_COLS / 2 +
          colSpan * (numOfItemsInFinalRow / 2 - countFromFinalItem) +
          1
      : GRID_COLS - colSpan * countFromFinalItem + 1
  }

  const textItems = props.items.map((textItem, index) => (
    <TextGridItem
      widgetSimpleTextHideMarginBottom={widgetSimpleTextHideMarginBottom}
      key={index}
      colSpan={colSpanArray}
      alignment={alignment}
      colStart={
        alignment === 'left'
          ? undefined
          : [
              1,
              getColStart(alignment, index, colSpanArray[1]),
              getColStart(alignment, index, colSpanArray[2]),
              getColStart(alignment, index, colSpanArray[3]),
            ]
      }
    >
      {textItem.subtitle && (
        <Subtitle alignment={alignment}>{textItem?.subtitle}</Subtitle>
      )}
      {textItem.title && (
        <Title
          as={textItem.titleHeadingLevel || 'h2'}
          headingTag={textItem.titleHeadingLevel || 'h2'}
          alignment={alignment}
          widgetSimpleTextHideMarginBottom={widgetSimpleTextHideMarginBottom}
        >
          {textItem.title}
        </Title>
      )}
      {textItem.content && (
        <Description alignment={alignment} content={textItem.content} />
      )}
      {textItem.buttons && textItem.buttons.length > 0 && (
        <ButtonsContainer
          alignment={alignment}
          widgetSimpleTextHideMarginBottom={widgetSimpleTextHideMarginBottom}
        >
          {textItem.buttons.map(
            (button, index) =>
              button?.text && (
                <PrefetchStyledButton
                  key={index}
                  renderedAs="a"
                  emphasis={
                    button?.emphasis || (index === 0 ? 'high' : 'medium')
                  }
                  href={button?.link}
                >
                  {button?.text}
                </PrefetchStyledButton>
              ),
          )}
        </ButtonsContainer>
      )}
    </TextGridItem>
  ))

  return <TextGridContainer columns={GRID_COLS}>{textItems}</TextGridContainer>
}
