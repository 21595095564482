import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'

export const TextGridContainer = styled(Grid)`
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: 0 ${spacing(1)};
  && {
    padding: 0;
    grid-gap: 0;
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    && {
      padding: 0 ${spacing(2)};
      margin: 0 ${spacing(2)};
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    && {
      padding: 0 ${spacing(2)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const TextGridItem = styled(GridItem)<{
  alignment: HorizontalAlignment
  widgetSimpleTextHideMarginBottom?: boolean
}>`
  justify-self: ${(props) => props.alignment};
  padding: 0 ${spacing(1)};
  word-break: break-word;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${(props) =>
      props.widgetSimpleTextHideMarginBottom
        ? props.theme.widget.simpleText.textGridItem?.marginBottom
        : `${spacing(4)};`};
  }
  margin-bottom: ${(props) =>
    props.widgetSimpleTextHideMarginBottom
      ? props.theme.widget.simpleText.textGridItem?.marginBottom
      : `0`};
`

export const Subtitle = styled.p<{
  alignment: HorizontalAlignment
}>`
  ${Text('small', 'alternate')}
  color: ${(props) => props.theme.colors.palette.greys.darker};
  text-align: ${(props) => props.alignment};
`

export const Title = styled.h2<{
  headingTag: 'h1' | 'h2'
  alignment: HorizontalAlignment
  widgetSimpleTextHideMarginBottom?: boolean
}>`
  ${(props) =>
    Text(
      props.theme.widget.simpleText.title[props.headingTag].font.entry,
      props.theme.widget.simpleText.title[props.headingTag].font.textStyle,
      props.theme.widget.simpleText.title[props.headingTag].font.hasScaledText,
    )};
  color: ${(props) => props.theme.colors.palette.greys.darker};
  text-align: ${(props) => props.alignment};
  margin: ${(props) =>
    props.widgetSimpleTextHideMarginBottom
      ? `${spacing(2.5)} 0 0 0;`
      : `${spacing(2.5)} 0;`};
`

export const Description = styled(SafeHtml)<{
  alignment: HorizontalAlignment
}>`
  ${Text('bodyText', 'default')}
  color: ${(props) => props.theme.colors.palette.greys.darker};
  margin-top: ${spacing(2)};
  text-align: ${(props) => props.alignment};

  h2 {
    ${Text('large1', 'alternate')}
  }

  ol {
    list-style: decimal;
    margin-top: ${spacing(3)};
    margin-left: ${spacing(2)};
  }

  a {
    ${Text('bodyText', 'default')};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const ButtonsContainer = styled.div<{
  alignment: HorizontalAlignment
  widgetSimpleTextHideMarginBottom?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.alignment};
  margin: ${(props) =>
    props.widgetSimpleTextHideMarginBottom
      ? `0 -${spacing(2)} 0 0;`
      : `${spacing(1)} -${spacing(2)} ${spacing(4)} 0;`};
`

export const StyledButton = styled(Button)`
  margin-top: ${spacing(2)};
  margin-right: ${spacing(2)};
`
