import * as React from 'react'

import {
  HorizontalAlignment,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import {
  EditorialWidget,
  GlobalSimpleTextCtaWidget,
  RichContent,
  Simpletextwidget,
  SimpleTextWidgetSet,
  Simpletextwidgetwithh1,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  ButtonProps,
  SimpleText,
  SimpleTextItem,
  SimpleTextProps,
} from '@thg-commerce/enterprise-widget-simple-text'

import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'

interface SimpleTextWidgetText {
  simpletextwidgetText: RichContent
}

interface EditorialWidgetText {
  editorialWidgetText: RichContent
}

interface GlobalSimpleTextCTAWidgetText {
  globalSimpleTextCTAWidgetText: string
}

interface SimpleTextWidgetSetWithChildren
  extends Omit<SimpleTextWidgetSet, 'banners'> {
  banners: Omit<Simpletextwidget & SimpleTextWidgetText, 'query' | 'id'>[]
}

export type SimpleTextWidgetRendererProps = BaseWidgetProps &
  (
    | Omit<EditorialWidget & EditorialWidgetText, 'query'>
    | Omit<Simpletextwidget & SimpleTextWidgetText, 'query'>
    | Omit<SimpleTextWidgetSetWithChildren, 'query'>
    | Omit<Simpletextwidgetwithh1 & SimpleTextWidgetText, 'query'>
    | Omit<GlobalSimpleTextCtaWidget & GlobalSimpleTextCTAWidgetText, 'query'>
  )

export const SimpleTextWidgetRenderer = (
  props: SimpleTextWidgetRendererProps,
) => {
  const { widgetSimpleTextHideMarginBottom } = useSiteConfig()

  switch (props.__typename) {
    case WidgetTypes.EditorialWidget:
      const editorialWidgetProps: SimpleTextProps = {
        alignment: 'left' as HorizontalAlignment,
        items: [
          {
            content: props.editorialWidgetText.content[0].content || '',
          } as SimpleTextItem,
        ],
      }
      return <SimpleText {...editorialWidgetProps} />
    case WidgetTypes.simpletextwidget:
      const simpleTextProps: SimpleTextProps = {
        alignment: props.textAlign as HorizontalAlignment,
        items: [
          {
            title: props.title || undefined,
            content: props.simpletextwidgetText.content[0].content || '',
          } as SimpleTextItem,
        ],
      }
      return <SimpleText {...simpleTextProps} />
    case WidgetTypes.simpleTextWidgetSet:
      const banners: SimpleTextItem[] = props.banners.map((item) => {
        return {
          title: item.title || undefined,
          content: item.simpletextwidgetText.content[0].content || '',
        } as SimpleTextItem
      })
      if (banners.length < 1) {
        return null
      }

      const simpleTextWidgetSetProps: SimpleTextProps = {
        alignment: (props.banners[0].textAlign ||
          'left') as HorizontalAlignment,
        items: [banners[0], ...banners.splice(1)],
      }
      return <SimpleText {...simpleTextWidgetSetProps} />
    case WidgetTypes.simpletextwidgetwithh1:
      const simpleTextH1Props: SimpleTextProps = {
        alignment: props.textAlign as HorizontalAlignment,
        items: [
          {
            title: props.title || undefined,
            content: props.simpletextwidgetText.content[0].content || '',
            titleHeadingLevel: 'h1',
          } as SimpleTextItem,
        ],
      }
      return <SimpleText {...simpleTextH1Props} />
    case WidgetTypes.GlobalSimpleTextCTAWidget:
      const simpleTextCTAProps: SimpleTextProps = {
        widgetSimpleTextHideMarginBottom,
        alignment: props.textAlign as HorizontalAlignment,
        items: [
          {
            title: props.title || undefined,
            content: props.globalSimpleTextCTAWidgetText || '',
            buttons: [
              {
                text: props.itemOneCTAText,
                link: props.itemOneURL,
                emphasis: 'high',
              } as ButtonProps,
            ],
          } as SimpleTextItem,
        ],
      }

      return <SimpleText {...simpleTextCTAProps} />
    default:
      return null
  }
}
